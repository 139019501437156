import React, { useState, useRef } from "react"
import useHover from "../hooks/useHover"
import ImageGallery from "react-image-gallery"
import ReactDOM from "react-dom"
import "react-image-gallery/styles/css/image-gallery.css"
/** @jsx jsx */
import { jsx } from "theme-ui"

/* useHover hook from excellent  https://usehooks.com/useHover/*/
const HomeSlider = props => {
  const [hoverRef, isHovered] = useHover()

  let sliderImages = []
  props.slides.map(slide => {
    return sliderImages.push({
      original: slide.img_src.localFile.childImageSharp.fixed.src,
      originalAlt: slide.img_alt,
      thumbnail: slide.img_src.localFile.childImageSharp.fixed.src,
      description: slide.caption,
      height: "800px",
      //width: "100%",
    })
  })
  return (
    <section //because of headroom element in header
      ref={hoverRef}
      className={isHovered ? "hoveredbaby" : "nothovered"}
      sx={{
        width: "100%",
        height: ["500px", "auto"],
        mt: "-200px",
        "@media screen and (max-width: 63.75em) and (min-width: 52em)": {
          mt: "-233px",
        },
        zIndex: 1,
        position: "relative",
        "& span.image-gallery-description": {
          fontFamily: "heading",
          fontSize: [3, 5, 6],
          fontWeight: "700",
          display: "block",
          position: "absolute",
          top: ["60%", "40%"],
          bg: "transparent",
          ml: [3, 4, 5],
          lineHeight: "1.2",
          width: "50%",
          textAlign: "left",
          //add class def for hiding nav
        },
        "&.nothovered div.image-gallery button.image-gallery-icon": {
          opacity: "0",
        },
        //sort image filling whole width
        "& .image-gallery-image": {
          objectFit: "cover",
          height: ["500px", "auto"],
        },
        //play/pause bigger touch area on mobile
        "& .image-gallery-fullscreen-button .image-gallery-svg, & .image-gallery-play-button .image-gallery-svg": {
          height: "28px",
          width: "28px",
        },
      }}
    >
      {/* {console.log(sliderImages)} */}
      <ImageGallery
        items={sliderImages}
        showThumbnails={false}
        autoPlay={true}
        slideInterval={4000}
        showFullscreenButton={false}
      />
    </section>
  )
}

export default HomeSlider
