import React from "react"
import { Box, Flex, Grid } from "theme-ui"
import SanitizedHTMLBodyCopy from "./sanitizedHTMLBodyCopy"
import Img from "gatsby-image"
import { Link } from "gatsby"
/** @jsx jsx */
import { jsx } from "theme-ui"
{
  /* CSS GRID version  https://bricampgomez.com/blog/how-to-overlap-images-in-css/*/
}

const HomeContentBlockCTA = props => {
  return (
    <Box
      sx={{
        mx: [0, 3, 5],
        mt: [3, 5],
        mb: [3, 4, 5],
        position: "relative",
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
      }}
    >
      <Box
        sx={{
          //top element
          gridColumn: ["1 / span 11", "1 / span 7", "1 / span 4"],
          gridRow: "1",
          mt: ["50%", "-30%", "20%"],
          zIndex: 1,
          alignSelf: "end",
          //iterations before ipad size
          "@media screen and (max-width: 73.5em) and (min-width: 52em)": {
            gridColumn: "1 / span 5",
          },
          "@media screen and (max-width: 62.5em) and (min-width: 52em)": {
            gridColumn: "1 / span 6",
          },
        }}
      >
        <Box
          sx={{
            background:
              "linear-gradient(90deg, rgba(244,5,88,1) 0%, rgba(226,30,54,1) 100%)",
            padding: 4,
            color: "white",
            pb: 5,
            "& h1": {
              color: "white",
            },
            "& div.bodyCopy": {
              fontSize: 3,
            },
          }}
        >
          <h1>{props.content.title}</h1>
          <SanitizedHTMLBodyCopy content={props.content.text} />
          {props.content.link_url && (
            <Link
              aria-label={`learn more about ${props.content.title}`}
              to={props.content.link_url}
              sx={{
                variant: "buttons.inverted",
              }}
            >
              Learn More
            </Link>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          //bottom element
          gridColumn: ["2 / -1", "3/ -1", "4 / -1"],
          gridRow: "1",
          pb: "10%",
        }}
      >
        <Img fluid={props.content.img.localFile.childImageSharp.fluid} />
      </Box>
    </Box>
  )
}

export default HomeContentBlockCTA
