import React from "react"
import { Box, Flex } from "theme-ui"
import SanitizedHTMLBodyCopy from "./sanitizedHTMLBodyCopy"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import HomeContentBlockWhatsOnCard from "./homeContentBlockWhatsOnCard"
/** @jsx jsx */
import { jsx } from "theme-ui"

const HomeContentWhatsOn = props => {
  //static image query
  const data = useStaticQuery(graphql`
    {
      image1: file(relativePath: { eq: "tracy-masterclass-thumb.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allStrapiEvent(sort: { fields: Date }) {
        edges {
          node {
            slug
            Biog
            Date
            dateFormatted: Date(formatString: "MMMM DD, YYYY")
            Img {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            Img_alt
            Open
            Title
            Type
            Venue
            strapiId
            Description
            Time
            blurb
          }
        }
      }
    }
  `)
  let today = new Date()
  const dd = String(today.getDate()).padStart(2, "0")
  const mm = String(today.getMonth() + 1).padStart(2, "0") //January is 0!
  const yyyy = today.getFullYear()
  today = yyyy + "-" + mm + "-" + dd
  //futureevents
  const futureEvents = data.allStrapiEvent.edges.filter(item => {
    return item.node.Date >= today
  })
  return (
    <Flex
      sx={{
        overflow: "hidden",
        mx: 3,
        display: ["block", "block", "flex"],
        mt: 5,
      }}
    >
      <Box sx={{ width: ["100%", "60%", "33%"] }}>
        {/* <SanitizedHTMLBodyCopy content={props.content.text} />
      <Img
        fluid={props.content.img.localFile.childImageSharp.fluid}
        sx={{ height: "200px" }}
      /> */}
        <Box
          sx={{
            p: 3,
            mb: 3,
            "& h1": {
              fontSize: 5,
            },
          }}
        >
          <h1 sx={{ mb: [1, 2, 3] }}>{props.content.title}</h1>
          <div
            sx={{
              pb: 3,
              fontSize: 3,
              color: "muted",
              "& p": { mb: [1, 2, 3], mt: [2, 2, 3] },
            }}
          >
            <SanitizedHTMLBodyCopy content={props.content.blurb} />
          </div>
          <Link
            to="/whatson"
            sx={{
              variant: "buttons.primary",
            }}
          >
            Upcoming Events
          </Link>
        </Box>
      </Box>
      <Flex
        sx={{
          width: ["100vw", "100vw", "63%"],
          display: ["flex", "flex"],
          overflowX: "auto",
        }}
      >
        {futureEvents.map((item, index) => {
          return <HomeContentBlockWhatsOnCard event={item} key={index} />
        })}
      </Flex>
    </Flex>
  )
}

export default HomeContentWhatsOn
