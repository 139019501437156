import React from "react"
import { Box, Flex } from "theme-ui"
import SanitizedHTMLBodyCopy from "./sanitizedHTMLBodyCopy"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { FaArrowRight } from "react-icons/fa"

const HomeContentBlockWhatsOnCard = ({ event }) => {
  //static image query
  const data = useStaticQuery(graphql`
    {
      image1: file(relativePath: { eq: "joe-smith-card.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const eventType = event.node.Type.replace(/_|-/g, " ")
  return (
    <Link
      to={`/event/${event.node.slug}`}
      sx={styles}
      aria-label={event.node.Title}
      className="event"
    >
      <Box className="eventImage">
        <Img
          fluid={event.node.Img.localFile.childImageSharp.fluid}
          alt={event.node.Img_alt}
        />
        <Box className="arrow">
          <FaArrowRight />
        </Box>
      </Box>
      <Box className="eventDetails">
        <h1>{event.node.Title}</h1>
        <h2>{event.node.dateFormatted}</h2>
        <h3>{eventType}</h3>
        <ul>{event.node.Time && <li>{event.node.Time}</li>}</ul>
      </Box>
    </Link>
  )
}

export default HomeContentBlockWhatsOnCard

const styles = {
  "&.event": {
    textDecoration: "none",
    mr: 2,
    "& .eventImage": {
      position: "relative",
      overflow: "hidden",
      height: ["220px", "400px"],
      ".gatsby-image-wrapper": {
        objectFit: "cover",
        maxWidth: "100%",
        width: ["180px", "300px"],
        height: ["220px", "400px"],
        transition: "all .3s ease",
      },
    },
    "& .arrow": {
      width: "50px",
      height: "50px",
      background: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      zIndex: 4,
      bottom: "0",
      transition: "background .3s ease",
      "& svg": {
        position: "relative",
        color: "accent",
        fontSize: 5,
      },
    },
    "& .eventDetails": {
      transition: "background .3s ease",
      "& h1": {
        color: "black",
        fontSize: 3,
        mt: 1,
        mb: 0,
        fontWeight: "700",
      },
      "& h2": {
        fontSize: 2,
        mt: 1,
        mb: 1,
        color: "muted",
        fontWeight: "400",
      },
      "& h3": {
        fontSize: 2,
        mt: 1,
        mb: 1,
        color: "accent",
        fontWeight: "400",
      },
      "& ul": {
        pl: 0,
        mt: 0,
        listStyle: "none",
        color: "muted",
      },
      "& li::before": {
        content: '"• "',
        color: "accent",
      },
    },
    //event hover
    "&:hover": {
      "& .eventImage": {
        ".gatsby-image-wrapper": {
          transform: "scale(1.2)",
        },
        "& .arrow": {
          background: "black",
        },
      },
    },
  },
}
