import React from "react"
/** @jsx jsx */
import { Box, jsx, Flex } from "theme-ui"
import SanitizedHTMLBodyCopy from "./sanitizedHTMLBodyCopy"
import Img from "gatsby-image"

const HomeContentBlockSecondary = props => {
  return (
    <Flex sx={{ display: ["block", "flex"] }}>
      <Box
        sx={{
          width: ["100%", "50%"],
          px: 4,
          fontSize: 3,
          "& div": { color: "muted" },
        }}
      >
        <h1 sx={{ fontSize: [4, 5] }}>{props.content.title}</h1>
        <SanitizedHTMLBodyCopy content={props.content.text} />
      </Box>
      <Box sx={{ width: ["100%", "50%"] }}>
        <Img fluid={props.content.img.localFile.childImageSharp.fluid} />
      </Box>
    </Flex>
  )
}

export default HomeContentBlockSecondary
