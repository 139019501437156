import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
/** @jsx jsx */
import { jsx } from "theme-ui"
import HomeSlider from "../components/homeSlider"
import HomeContentBlocks from "../components/homeContentBlocks"
import HomePrimaryBlock from "../components/homePrimaryBlock"
import { FaBeer } from "react-icons/fa"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query HomepageQuery {
      strapiHomepage {
        id
        header_slider {
          caption
          dark_bg
          id
          url
          url_text
          img_alt
          img_src {
            localFile {
              childImageSharp {
                fixed(fit: COVER, height: 1420, width: 1900) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        primaryBlock {
          title
          text
          img {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          img_alt
          link_url
          link_text
        }

        contentBlocks {
          __typename
          strapi_component
          id
          img {
            id
            localFile {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          img_alt
          title
          text
          link_url
          link_text
          blurb
          news_item {
            id
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Nurturing Young Talent" pathname="/" />
      <HomeSlider slides={data.strapiHomepage.header_slider} />

      <HomePrimaryBlock block={data.strapiHomepage.primaryBlock} />

      <HomeContentBlocks blocks={data.strapiHomepage.contentBlocks} />
      {/* <div>
        <Link to="/page-2/">Go to page 2</Link> <br />
        <Link to="/using-typescript/">Go to "Using TypeScript"</Link>
      </div> */}
    </Layout>
  )
}
export default IndexPage
