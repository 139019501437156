import React from "react"
import { Box, Flex } from "theme-ui"
import SanitizedHTMLBodyCopy from "./sanitizedHTMLBodyCopy"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
/** @jsx jsx */
import { jsx } from "theme-ui"

const HomeContentNews = props => {
  //static image query
  const data = useStaticQuery(graphql`
    {
      defaultImage: file(relativePath: { eq: "default-news-thumb.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allStrapiNewsItem(sort: { fields: date, order: DESC }) {
        edges {
          node {
            strapiId
            title
            date
            dateFormatted: date(formatString: "MMMM DD, YYYY")
            content
            blurb
            slug
            img {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            img_alt
            news_categories {
              tag
            }
          }
        }
      }
    }
  `)
  //console.log(props.content.news_item)
  let newsItem = undefined
  if (props.content.news_item) {
    newsItem = data.allStrapiNewsItem.edges.filter(item => {
      return item.node.strapiId === parseInt(props.content.news_item.id)
    })
    //take it out of the array as only one value
    newsItem = newsItem[0]
  } else {
    newsItem = data.allStrapiNewsItem.edges[0]
  }

  return (
    <Box>
      {/* <h1>{props.content.title}</h1> */}
      {/* <SanitizedHTMLBodyCopy content={props.content.text} />
      <Img
        fluid={props.content.img.localFile.childImageSharp.fluid}
        sx={{ height: "200px" }}
      /> */}

      <Flex
        sx={{
          mx: [0, 0, 5],
          my: [4, 3, 5],
          flexDirection: "row-reverse",
          display: ["block", "flex"],
        }}
      >
        <Flex
          sx={{
            width: ["100%", "60%", "50%"],
            position: "relative",
            display: "grid",
            gridTemplateColumns: "repeat(12, 1fr)",
          }}
        >
          <Box
            sx={{
              //top element
              gridColumn: ["1 / span 11", "1 / span 11", "1 / span 9"],
              gridRow: "1",
              mt: ["0%", "-5%", "-5%"],
              zIndex: 1,
              height: ["auto", "70%", "auto"],
            }}
          >
            <Img
              fluid={
                newsItem.node.img
                  ? newsItem.node.img.localFile.childImageSharp.fluid
                  : data.defaultImage.childImageSharp.fluid
              }
              alt={newsItem.node.img_alt}
              sx={{
                width: "100%",
                height: ["100%"],
                position: "absolute",
                // top: "-16.6%", //-50px
                // left: "20%", //60px
                // zIndex: 5,
              }}
            />
          </Box>

          <Box
            sx={{
              width: "100%",
              height: ["100%", "70%", "100%"],
              background:
                "linear-gradient(90deg, rgba(244,5,88,1) 0%, rgba(226,30,54,1) 100%)",
              //position: "absolute",
              //bottom element
              gridColumn: "3 / span 11",
              gridRow: "1",
              mt: ["-7%", "6%", "6%"],
            }}
          />
        </Flex>
        <Box
          sx={{
            width: ["100%", "40%", "50%"],
            mt: [3, "auto"],
            ml: ["auto", 3, "auto"],
            px: [3, "auto", "auto"],
            "& span": {
              color: "accent",
              fontWeight: "bold",
              fontFamily: "heading",
              zIndex: 6,
            },
          }}
        >
          <span>Latest News</span>
          <hr
            sx={{
              width: "12%",
              display: "inline-block",
              color: "accent",
              backgroundColor: "accent",
              border: 0,
              height: "2px",
              ml: 2,
              mr: ["-175px"],
              zIndex: 6,
            }}
          />
          <Box
            sx={{
              width: ["100%", "94%", "80%"],
              "& h1": {
                mb: 3,
              },
              "& h2": {
                color: "muted",
                fontWeight: 400,
                fontSize: 3,
                mt: 0,
              },
              "& ul": {
                pl: 0,
                listStyle: "none",
                color: "muted",
              },
              "& li::before": {
                content: '"• "',
                color: "accent",
              },
            }}
          >
            <h1>{newsItem.node.title}</h1>
            <div sx={{ pb: 3, fontSize: 3, color: "muted" }}>
              {newsItem.node.blurb && (
                <SanitizedHTMLBodyCopy content={newsItem.node.blurb} />
              )}
            </div>
            {/* {props.content.link_url && <button>{props.content.link_text}</button>} */}
            <Link
              to={`/news/${newsItem.node.slug}`}
              sx={{
                variant: "buttons.primary",
              }}
              aria-label={`learn more about ${newsItem.node.title}`}
            >
              Go to News Item
            </Link>
            <Link
              to={`/news`}
              sx={{
                variant: "buttons.primary",
                mx: 2,
              }}
              aria-label={`News List`}
            >
              More News
            </Link>
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}

export default HomeContentNews
