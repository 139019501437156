/** @jsx jsx */
import { Box, jsx, Flex } from "theme-ui"
import React from "react"
import SanitizedHTMLBodyCopy from "./sanitizedHTMLBodyCopy"
import Img from "gatsby-image"

const HomePrimaryBlock = props => {
  return (
    <section
      sx={{
        background:
          "linear-gradient(90deg, rgba(244,5,88,1) 0%, rgba(226,30,54,1) 100%)",
        //background: "red",
        //for img overlapping bottom
        //backgroundSize: "100% 80%", //back to normal cus long text

        backgroundRepeat: "no-repeat",
        color: "white",
        padding: [3, "32px 32px 64px 32px", 5],

        maxWidth: ["100%", "85%"],
        width: ["100%", "85%"],
        margin: ["0", "0 auto"],
        mt: ["0.1em", "-18%", null],

        zIndex: 9,
        position: "relative",
        "@media screen and (max-width: 67em) and (min-width: 52em)": {
          pt: "50px",
          //backgroundSize: "100% 85%",
          px: "45px",
        },
      }}
    >
      <Flex
        sx={{
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          display: ["block", "flex"],
        }}
      >
        <div
          sx={{
            px: [1, 2, 3],
            py: [2, 3],
            pt: [2, 0, 0],
            width: ["100%", "60%", "45%"],

            "& h2": {
              fontSize: [4, 4, 6],
              color: "white",
              mt: 0,
              pl: [null, "10px", "auto"],
              textAlign: ["center", "left"],
            },
            "& > div": {
              fontSize: 3,
              fontWeight: "300",
              pl: [null, "10px", "auto"],
              pb: [0, 3, 3],
            },
            "@media screen and (max-width: 72.5em) and (min-width: 52em)": {
              width: "48%",
              h2: {
                fontSize: "40px",
              },
            },
            "@media screen and (max-width: 67em) and (min-width: 52em)": {
              width: "50%",
              h2: {
                fontSize: "37px",
              },
            },
            "@media screen and (max-width: 57em) and (min-width: 52em)": {
              p: {
                mt: 0,
              },
              h2: {
                fontSize: "34px",
                mb: "20px",
              },
            },
          }}
        >
          <h2>{props.block.title}</h2>
          <SanitizedHTMLBodyCopy
            content={props.block.text}
            className="textContent"
          />
        </div>
        <div
          sx={{
            px: ["10px", 0, 3],
            mr: ["-50px", "auto", "auto"],
            width: ["100%", "45%", "50%"],
          }}
        >
          <Img
            fluid={props.block.img.localFile.childImageSharp.fluid}
            sx={{
              height: "100%",
              width: "100%",
              //
              // top: "100px",
            }}
            alt={props.block.img_alt}
          />
        </div>
      </Flex>
    </section>
  )
}
export default HomePrimaryBlock
