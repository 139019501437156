import React from "react"
import HomeContentBlockSecondary from "../components/homeContentBlockSecondary"
import HomeContentBlockCTA from "./homeContentBlockCTA"
import HomeContentBlockWhatsOn from "./homeContentBlockWhatsOn"
import HomeContentBlockNews from "./homeContentBlockNews"
import HomeContentBlockFeaturedMasterclass from "./homeContentBlockFeaturedMasterclass"
/** @jsx jsx */
import { jsx } from "theme-ui"
const HomeContentBlocks = props => {
  return (
    <section sx={{ pb: 4, maxWidth: "100vw" }}>
      {props.blocks.map((block, index) => {
        switch (block.strapi_component) {
          case "homepage.secondary-block":
            return (
              <HomeContentBlockSecondary
                content={block}
                key={`homecontentblock${index}`}
              />
            )
          case "homepage.cta-block":
            return (
              <HomeContentBlockCTA
                content={block}
                key={`homecontentblock${index}`}
              />
            )
          case "homepage.news-block":
            return (
              <HomeContentBlockNews
                content={block}
                key={`homecontentblock${index}`}
              />
            )
          case "homepage.whats-on-block":
            return (
              <HomeContentBlockWhatsOn
                content={block}
                key={`homecontentblock${index}`}
              />
            )
          // case "homepage.featured-masterclass-block":
          //   return (
          //     <HomeContentBlockFeaturedMasterclass
          //       content={block}
          //       key={`homecontentblock${index}`}
          //     />
          //   )
        }
      })}
    </section>
  )
}

export default HomeContentBlocks
